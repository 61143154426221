// @ts-check

import Sentry from './utils/errors.js';
Sentry.init(
  {
    app: [],
    telemetry: false,
    dsn: 'https://7958f3a0fd584fc2ad783a03dbe35f3f@o270311.ingest.us.sentry.io/4504632735301632',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(), // TODO: Set ("Document-Policy", "js-profiling");
    ],
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      'localhost',
      import.meta.env.VITE_USER_EVENT_TRACKING_SERVICE_API_URL,
      import.meta.env.VITE_USER_PROFILE_SERVICE_API_URL,
      import.meta.env.VITE_AUTH_SERVICE_API_URL,
      /^\//,
    ],
    ignoreErrors: [
      /SecurityError: DOM Exception$/,
      /SecurityError: The operation is insecure/,
      /NotAllowedError/,
      /TypeError/,
      /sendBeacon/,
      /\.*The operation is insecure\.*/,
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'conduitPage',
      'Script error.',
      'consoleLog',
      'chrome-extension',
      'crypto.randomUUID is not a function',
      'Unexpected token',
      'not_verified',
      'unknown_user_or_password',
      /Object captured as exception \.*/,
    ],
    denyUrls: [
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /localhost\.*/i,
      /\.svg/i,
      /googletag/,
      /google\*\./,
      /\*.google-analytics\.com/,
      /\*.algolia.net/,
    ],
    normalizeDepth: 8,
    profilesSampleRate: 1.0,
    debug: false, // TODO: Toggle this on if you need it...
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  },
);

/**
 * Put everything in here that should be imported between pages
 */
const defaultImports = () => {
  import('@/pages/header/header.js');
  import('@/cookieconsent/cookieconsent.js');
};

// @ts-ignore
window.et = window.et || {};
// @ts-ignore
window.et.page = {
  initBase() {
    defaultImports();
  },

  initFrontPage() {
    defaultImports();
    import('@/pages/front/frontPage.js');
  },

  initSearchPage() {
    defaultImports();
    import('@/pages/search/searchPage.js');
  },

  initListingPage() {
    defaultImports();
    import('@/pages/listing/listingPage.js');
  },

  initAccountsPage() {
    defaultImports();
    import('@/pages/accounts/accountsPage.js');
  },

  initAccountPage() {
    defaultImports();
    import('@/pages/account/accountPage.js');
  },

  initStatisticsPage() {
    defaultImports();
    import('@/pages/statistics/statisticsPage.js');
  },

  initAuthComponents() {
    defaultImports();
    import('@/pages/auth/authComponents.js');
  },

  initAdvertiserPage() {
    defaultImports();
    import('@/pages/advertiser/advertiserPage.js');
  },
};
